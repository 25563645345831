export const TEXTS = {
    templates: {
        types: {
            default: 'Default',
            interactive: 'Interactive',
            flow: 'Custom Flow'
        },
        interactionTypes: {
            schedule: {
                label: 'Automatic Prospecting of Services',
                buttons: {
                    yes: 'Yes',
                    no: 'No'
                }
            },
            scheduleConfirmation: {
                label: 'Automatic Appointment Confirmation',
                buttons: {
                    yes: 'Yes',
                    no: 'No',
                    reschedule: 'Reschedule'
                }
            },
            ticketGeneration: {
                label: 'Automatic Event Generation',
                buttons: {
                    yes: 'Yes',
                    no: 'No'
                }
            },
            updateKm: {
                label: 'Automatic Mileage Update',
                buttons: {
                    yes: 'Yes',
                    doNotRemember: 'Do Not Remember',
                    doNotOwn: 'Do Not Own Anymore'
                }
            },
            iaInteractiveFlow: {
                label: 'Artificial intelligence (active conversations)',
                buttons: {
                    yes: 'Yes',
                    no: 'No'
                }
            },
            selfSurvey: {
                label: 'Self-Survey (satisfaction survey)',
                buttons: {
                    yes: 'Yes',
                    no: 'No'
                }
            },
            userNotification: {
                label: 'Automatic User Notification',
                buttons: {
                    accessTicket: 'Access the Event'
                }
            },
            ownerNotification: {
                label: 'Automatic Manager Notification',
                buttons: {
                    yes: 'Yes',
                    no: 'No'
                }
            }
        },
        categories: {
            authentication: { label: 'Authentication', description: '' },
            marketing: { label: 'Marketing', description: '' },
            utility: { label: 'Utility', description: '' }
        },
        supportedLanguages: {
            enUs: 'English (United States)',
            es: 'Spanish',
            ptBr: 'Portuguese (Brazil)'
        },
        fields: {
            CONSORTIUM_CONTRACT_NUMBER: 'Consortium contract number',
            CONSORTIUM_GROUP_ID: 'Consortium group',
            CONSORTIUM_QUOTA_ID: 'Consortium quota',
            CONSORTIUM_CS_DUE_DATE: 'Consortium due date',
            CONSORTIUM_CS_BANK_ID: 'Consortium bank',
            CONSORTIUM_CS_ASSEMBLY: 'Consortium assembly',
            CONSORTIUM_CS_DIGIT: 'Consortium digit',
            CONSORTIUM_CS_NO_JURIDICO: 'Consortium in legal',
            CONSORTIUM_CS_NUMBER_DAYS: 'Consortium number of days',
            CONSORTIUM_CS_PX_ASSEMBLY: 'Consortium px assembly',
            TICKET_ID: 'Ticket',
            TICKET_USER: 'Consultant',
            TICKET_COMPANY: 'Company',
            TICKET_COMPANY_ADDRESS: 'Company Address',
            TICKET_COMPANY_PHONE: 'Store phone',
            TICKET_TYPE: 'Event type',
            TICKET_DATE: 'Service Date',
            TICKET_HOUR: 'Service Hour',
            TICKET_CURRENT_USER: 'Current Consultant',
            TICKET_CURRENT_USER_FIRST_NAME: 'Current Consultant First Name',
            TICKET_URL_RESEARCH: 'Search Site',
            TICKET_DATE_VISIT: 'Visit Date at Store',
            TICKET_HOUR_VISIT: 'Visit Hour at Store',
            CUSTOMER_NAME: 'Customer Name',
            CUSTOMER_FULLNAME: 'Customer Full Name',
            VEHICLE_MODEL: 'Vehicle Model',
            VEHICLE_DS_PRODUCT: 'Vehicle Model (Description)',
            VEHICLE_PLATE: 'Vehicle Plate',
            VEHICLE_CHASSI: 'Vehicle Chassis',
            VEHICLE_SELLER: 'Vehicle Seller',
            SERVICE_VEHICLE_MODEL: 'Service Vehicle Model',
            SERVICE_VEHICLE_PLATE: 'Service Vehicle Plate',
            SERVICE_CONSULTANT: 'Service Consultant',
            SERVICE_VEHICLE_CHASSI: 'Service Vehicle Chassis',
            SERVICE_VEHICLE_DS_MODEL: 'Service Vehicle Model O.S (Description)',
            NEGOTIATION_BRAND_INTEREST: 'Vehicle brand of interest',
            NEGOTIATION_MODEL_INTEREST: 'Vehicle model of interest',
            BILLET_CUSTOMER_NAME: 'Customer name (Billet)',
            BILLET_INVOICE: 'Invoice number (Billet)',
            BILLET_DEPARTMENT: 'Department (Billet)',
            BILLET_DUE_DATE: 'Due Date (Billet)',
            BILLET_ISSUE_DATE: 'Issue (Billet)',
            BILLET_VALUE: 'Value (Billet)'
        },
        deleteErrorMessages: {
            noTemplatesToDelete: 'No templates to delete.',
            credentialNotFound: 'Credential not found for the channel.'
        },
        createErrorMessages: {
            duplicateNameAndLanguage: 'Template with given name and language already exists.'
        },
        uploadTemplateImageMessages: {
            uploadError:
                'There is an issue uploading the image to Gupshup while creating the template! Please try again in a few minutes.'
        }
    }
}
