import { TemplateMessage } from './internal-communication'

export interface ContextMessage {
    id: string
    from: string
}
export type HandleFile = {
    body: Buffer
    mimeType: string
    filename: string
    key: string
}

export type ReplyMessage = {
    id?: string
    context?: ContextMessage
}
export type variableMap = { key: string; value: string }
export interface TextMessage extends ReplyMessage {
    type: 'TEXT'
    text: string
    referral?: Referral
}

export interface Referral {
    sourceUrl: string
}
export interface UnSupported extends ReplyMessage {
    type: 'UNSUPPORTED'
}
export interface MediaMessage extends ReplyMessage {
    type: 'VIDEO' | 'VOICE' | 'AUDIO' | 'IMAGE' | 'STICKER' | 'DOCUMENT'
    mediaUrl: string
    caption?: string
    docCaption?: string
    url?: string
    text?: string
}

export interface DataPhone {
    phone: string
    waId?: string
    type?: 'CELL' | 'MAIN' | 'IPHONE' | 'HOME' | 'WORK'
}

export interface DataEmail {
    email: string
    type?: 'HOME' | 'WORK'
}

export interface DataUrl {
    url: string
    type?: 'HOME' | 'WORK'
}

export interface DataContact {
    name?: Name
    phones?: DataPhone[]
    emails?: DataEmail[]
    urls?: DataUrl[]
}

export interface Name {
    firstName: string
    formattedName: string
    lastName: string
}

export interface ContactMessage extends ReplyMessage {
    type: 'CONTACT'
    contacts: DataContact[]
}

export interface LocationMessage extends ReplyMessage {
    type: 'LOCATION'
    longitude: number
    latitude: number
    name?: string
    address?: string
    url?: string
}

export interface Contact {
    name: string
}

export interface Price {
    pricePerMessage: number
    currency: string
}

export interface InteractiveReply {
    message?: ActiveMessage
    template?: TemplateMessage
    room?: string
}

export interface WrapperMessage {
    from: string
    to: string
    integrationType: string
    receivedAt: string
    messageId: string
    message: Message
    contact: Contact
    createAt: number
    price?: Price
    readAt?: number
    room?: string
    channel?: string
    status?: string
    error?: string
    interactiveReply?: InteractiveReply
    interactionType?: InteractionType
    deliveredAt?: number
    deletedAt?: number
    callbackData?: string
    pairedMessageId?: string
}

export type Footer = {
    text: string
}

export type Body = {
    text: string
}

export type TemplateTextHeader = {
    type: 'TEXT'
    placeholder: 'placeholder value'
}

export type TextHeader = {
    type: 'TEXT'
    text: string
}
export type MultimediaHeader = {
    type: 'VIDEO' | 'IMAGE'
    mediaUrl: string
}

export type DocumentHeader = {
    type: 'DOCUMENT'
    mediaUrl: string
    filename: string
}

export type LocationHeader = {
    type: 'LOCATION'
    latitude: number
    longitude: number
}

export type Section = {
    title: string
    rows: {
        id: string
        title: string
        description?: string
    }[]
}

export type Action = {
    title: string
    sections: Section[]
}

export type ListMessage = ReplyMessage & {
    type: 'INTERACTIVE_LIST_REPLY'
    body: Body
    id?: string
    description?: string
    action: Action
    header?: TextHeader
    footer?: Footer
}

export type ReplyButtonMessage = {
    type: 'BUTTON'
    text: string
    payload: string
    context: ContextMessage
}

export type InteractiveButtonMessage = {
    type: 'REPLY'
    id: string
    title: string
}

export type InteractiveHeader = TextHeader | MultimediaHeader | DocumentHeader

export type ButtonMessage = ReplyMessage & {
    type: 'INTERACTIVE_BUTTON_REPLY'
    body: Body
    action: {
        buttons: InteractiveButtonMessage[]
    }
    header?: InteractiveHeader
    footer?: Footer
}

export type Message =
    | TextMessage
    | MediaMessage
    | ContactMessage
    | LocationMessage
    | ActiveMessage
    | ListMessage
    | ButtonMessage
    | ReplyButtonMessage
    | UnSupported

export interface DataContactDestination {
    phoneNumber?: string
    emailAddress?: string
    pushRegistrationId?: string
}

export interface StatusMessage {
    id?: number
    groupName?: string
    groupId?: string
    action?: string
    name?: string
    description?: string
}

export interface MessageSent {
    to: DataContactDestination
    messageId: string
    status: StatusMessage
}

export interface ResponseMessageSent {
    messages: MessageSent[]
    bulkId?: string
}

export type ReplyContext = {
    sender?: {
        name: string
    }
    ticket?: {
        id: number
    }
    campaign?: {
        id: number
        name?: string
        relatedTicketId: number
        language?: string
        keepsTicketOpen?: boolean
    }
    vehicle?: {
        id: string
        company: number
        plate: string
        model: string
    }
    customer: {
        id: number
    }
    company: {
        id: number
    }
    syoAlert?: {
        idUser: number
    }
    shippingPolicy?: {
        start: string
        end: string
        bitmask: number
    }
    alertInfo?: {
        teamName: string
        ownerName: string
        dataEventsDelayed: {
            totalEvents: string
            sellerId: number
            visualizationName: string
            infoEvents: string
        }[]
    }[]
    search?: { id: number }
}

export interface ActiveMessageButton {
    type: 'URL' | 'QUICK_REPLY'
    parameter: string
}

export interface ActiveMessage {
    id: string
    type: 'ACTIVE_MESSAGE'
    templateName: string
    templateData: {
        body: {
            placeholders: string[]
        }
        buttons?: ActiveMessageButton[]
        header?: LocationHeader | MultimediaHeader | TemplateTextHeader | DocumentHeader
        footer: string | null
    }
    language: string
    preview: TextMessage & {
        replyContext?: ReplyContext
        template?: { id: string; interactive: boolean; interactionType?: InteractionType }
    }
    context?: ContextMessage
    channelPhone: string
    customerName: string
    canCreateRoom?: boolean
}

export interface TicketGenerationOptions {
    group: string
    type: string
}

export type FlowOptions = {
    flowId: string
    negativeResponseMessage: string
}

export type ButtonMessageReply = { id: string; title: string }

export type ListMessageReply = { id: string; title: string; description: string }

export type InteractionType =
    | 'SCHEDULE'
    | 'SCHEDULE_CONFIRMATION'
    | 'TICKET_GENERATION'
    | 'USER_NOTIFICATION'
    | 'UPDATE_KM'
    | 'SYO_ALERT'
    | 'OWNER_NOTIFICATION'
    | 'IA_INTERACTIVE_FLOW'
    | 'SELF_SURVEY'

export const syoAlertOptions = ['SYO_ALERT', 'OWNER_NOTIFICATION', 'USER_NOTIFICATION']
export const enableUserNotification = ['SYO_ALERT', 'OWNER_NOTIFICATION']

export const handleResponseStatus = (
    msg:
        | {
              messageId: string
              status: {
                  groupName: string
                  description: any
              }
              to: {
                  phoneNumber: string
              }
          }
        | MessageSent
) => {
    if (msg?.status.groupName === 'PENDING') {
        msg.status.groupName = 'RECEIVED_BY_PARTNER'
    }
    return msg
}
