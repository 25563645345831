import { StatusType } from './channel'
import { EventGroupInfo } from './commons'

export enum FlowType {
    ACTIVE = 'ACTIVE',
    RECEPTIVE = 'RECEPTIVE'
}

export enum FlowStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    ERROR = 'ERROR'
}

export enum FlowNodeType {
    ROBOT = 'ROBOT',
    TICKET = 'TICKET'
}

export enum FlowAnswerType {
    TEXT = 'TEXT',
    BUTTON = 'BUTTON',
    LIST = 'LIST'
}

enum FlowHeaderType {
    DOCUMENT = 'DOCUMENT',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO'
}

type FlowHeaderDocument = {
    type: FlowHeaderType.DOCUMENT
    url: string
}

type FlowHeaderImage = {
    type: FlowHeaderType.IMAGE
    url: string
}

type FlowHeaderVideo = {
    type: FlowHeaderType.VIDEO
    url: string
}

type FlowAnswerText = {
    type: FlowAnswerType.TEXT
    id: string
}

type FlowAnswerButton = {
    type: FlowAnswerType.BUTTON
    buttons: { id: string; text: string }[] // Máximo de 3 botões
}

type FlowAnswerList = {
    type: FlowAnswerType.LIST
    title: string
    items: { id: string; text: string }[] // Máximo de 10 itens
}

type TicketOptions = {
    eventGroup: string
    eventType: string
}

export type FlowBuilderHeader = FlowHeaderDocument | FlowHeaderImage | FlowHeaderVideo

export type FlowNodeRobotData = {
    type: FlowNodeType.ROBOT
    name: string
    answer: FlowAnswerText | FlowAnswerButton | FlowAnswerList
    message: string
    header?: FlowHeaderDocument | FlowHeaderImage | FlowHeaderVideo
}

export type FlowNodeTicketData = {
    type: FlowNodeType.TICKET
    name: string
    ticketOptions: TicketOptions
    message: string
    error: boolean
    header?: FlowBuilderHeader
}

export type FlowNode = {
    id: string
    data: FlowNodeRobotData | FlowNodeTicketData
    position: {
        x: number
        y: number
    }
    nextNode?: string
    previousNode?: string
}

export type FlowEdge = {
    id: string
    type: string
    source: string
    target: string
    label: string
}

export type FlowInfoNode = FlowNode & {
    nextNodes: string[]
    previousNodes: string[]
    processed: boolean
    answered: boolean
}

export type SaveFlowRequest = Omit<Flow, 'group' | 'channel'>

export type Flow = {
    _id: string
    group: string
    channel: string
    name: string
    type: FlowType
    status?: FlowStatus
    menus?: string[]
    templates?: string[]
    nodes: FlowNode[]
    edges: FlowEdge[]
}

export type FlowRequest = {
    group: string
    channel: string
    removedFlows?: string[]
    page?: number
    limit?: number
    type?: FlowType
    name?: string
    paginated: boolean
    toMenus?: boolean
}

export type FlowRequestForTemplates = {
    group: string
    channel: string
}

export type FlowRequestDeleteFromTemplates = {
    group: string
    channel: string
    flowId: string[]
}

export type DraftFlowBuilder = { save?: Flow[]; remove?: string[] }

export type FlowBuilderResponse = {
    configured: Pagination<Flow>
    options: EventGroupInfo[]
    status: FlowBuilderStatus
}

export type PaginationMeta = {
    totalItems: number
    itemCount: number
    itemsPerPage: number
    totalPages: number
    currentPage: number
}

export type Pagination<T> = {
    items: T[]
    meta: PaginationMeta
}

export type FlowBuilderStatus = {
    type: StatusType
    error?: { missingTicketInfo: boolean }
    warning?: Record<string, unknown>
}

export type ChannelDraftFlowBuilder = {
    flow?: Flow
    deleteFlow?: string
}

export type FlowBuilderInfo = {
    nodes: FlowInfoNode[]
    extractedData?: Record<string, unknown>
    lastProcessed?: string
}

export type FlowBuilderTemplateOption = {
    use: boolean
    message: string
    alternatives?: FlowAnswerText | FlowAnswerButton | FlowAnswerList
    header?: FlowHeaderDocument | FlowHeaderImage | FlowHeaderVideo
}

export type FlowBuilderSaveResponse = { equal: boolean; oldId: string; newId: string }

export function mapEdgesToNodes(flow: Flow): FlowInfoNode[] {
    const { nodes, edges } = flow
    const nextNodeMap: { [source: string]: string[] } = {}
    const previousNodeMap: { [target: string]: string[] } = {}

    edges.forEach((edge) => {
        if (!nextNodeMap[edge.source]) {
            nextNodeMap[edge.source] = []
        }
        nextNodeMap[edge.source].push(edge.target)

        if (!previousNodeMap[edge.target]) {
            previousNodeMap[edge.target] = []
        }
        previousNodeMap[edge.target].push(edge.source)
    })

    return nodes.map((node) => ({
        ...node,
        nextNodes: nextNodeMap[node.id] || [],
        previousNodes: previousNodeMap[node.id] || [],
        processed: false,
        answered: false
    }))
}
